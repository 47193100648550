import axios from "axios";
import { ElNotification } from "element-plus";

async function getPresignedDataAndUploadToS3(presignFunction, file, ...args) {
  try {
    let { data } = await presignFunction(file.name, ...args);

    let config = {
      method: "put",
      url: data.uploadUrl,
      headers: {
        "Content-Type": file.type || "application/octet-stream",
      },
      data: file,
    };

    await axios(config);
    return data;
  } catch (e) {
    ElNotification({
      message: "Failed to upload file.",
      type: "error",
      position: "bottom-right",
    });
  }
}

async function uploadToS3(file, presignedData = null) {
  try {
    if (!presignedData) return;

    let config = {
      method: "put",
      url: presignedData.uploadUrl,
      headers: {
        "Content-Type": file.type,
      },
      data: file,
    };

    await axios(config);
    const attachmentDetails = {
      id: presignedData.key,
      storage: presignedData.storage,
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    };

    return attachmentDetails;
  } catch (error) {
    ElNotification({
      message: "Failed to upload file.",
      type: "error",
      position: "bottom-right",
    });
  }
}
export { getPresignedDataAndUploadToS3, uploadToS3 };
